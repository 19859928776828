import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import {
  Input,
  PhoneInput,
  PhoneInputData,
  PhoneInputRef,
} from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useInputFieldsContext } from '@src/contexts/InputFieldsContext';
import { InputName } from '@src/contexts/InputFieldsContext/types';
import { Path } from '@src/types/routes';

import { useCreateOrderHandler } from './hooks/useCreateOrderHandler';

import { InputsWrapper } from './styled';

export default function RecipientDetails() {
  const phoneRef = useRef<PhoneInputRef>(null);

  const { inputFields, recordInputFields } = useInputFieldsContext();
  const { isError, isLoading, createOrderHandler } = useCreateOrderHandler();
  const { setButtonState } = useFlowManagerContext();

  const isActive =
    !!inputFields.recipientName &&
    !!inputFields.recipientSurname &&
    !!inputFields.recipientNumber;

  function onChangeHandler(event: ChangeEvent<HTMLInputElement>): void {
    const { value, name } = event.target;
    recordInputFields({ ...inputFields, [name as InputName]: value });
  }

  function onPhoneChange({ fullNumber, number }: PhoneInputData): void {
    recordInputFields({
      ...inputFields,
      recipientFullNumber: fullNumber,
      recipientNumber: number,
    });
  }

  const buttonAction = useCallback(() => {
    const isNumberCorrect = phoneRef.current?.getPhoneInfo().isNumberCorrect;

    if (!isNumberCorrect) {
      return;
    }

    createOrderHandler();
  }, [createOrderHandler]);

  useEffect(() => {
    setButtonState({
      text: 'Next',
      isActive,
      isLoading,
      buttonAction,
    });
  }, [isActive, isLoading, setButtonState, buttonAction]);

  useEffect(() => {
    if (isError) {
      setButtonState({
        text: 'Verification Phone number',
        isActive: true,
        isLoading: false,
        link: { to: Path.Authorization },
      });
    }
  }, [isError, setButtonState]);

  return (
    <FlowManager title="Enter the recipient's details">
      <InputsWrapper>
        <Input
          label="Name"
          name={InputName.RecipientName}
          value={inputFields.recipientName}
          onChange={onChangeHandler}
          required
        />
        <Input
          label="Second name"
          name={InputName.RecipientSurname}
          value={inputFields.recipientSurname}
          onChange={onChangeHandler}
          required
        />
        <PhoneInput
          ref={phoneRef}
          initialNumber={inputFields.recipientFullNumber}
          onChange={onPhoneChange}
          countryPickerMode="onlyNumber"
        />
      </InputsWrapper>
    </FlowManager>
  );
}
