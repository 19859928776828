import styled from 'styled-components';

import BoxImage from '@src/assets/images/box.png';
import { StyledWrapper, Title as TitleBase } from '@src/theme/common';

export const DeliveryDetailsStyled = styled(StyledWrapper)`
  display: block;

  background-image: url(${BoxImage});
  background-repeat: no-repeat;
  background-size: 135px;
  background-position: bottom right;

  padding-right: 200px;
`;

export const Title = styled(TitleBase)`
  text-align: left;

  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const CellDetailsWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Bold = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
`;

export const Text = styled.p``;
