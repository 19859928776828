export type InputType = 'width' | 'height' | 'length';

export type ChangeType = 'plus' | 'minus';

export enum SelectedCellParams {
  width = 'width',
  height = 'height',
  length = 'depth',
}

export interface InputOfDimensionProps {
  type: InputType;
}
