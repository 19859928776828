import { css } from 'styled-components';

import { Cell } from '../styles';

const BhScreenDbStyled = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1px;

  ${Cell}:nth-child(1) {
    width: 100%;
    height: 12%;
  }
  ${Cell}:nth-child(2) {
    width: 100%;
    height: 50%;
  }

  ${Cell}:nth-child(3) {
    flex: 1;
    height: 6%;
  }

  ${Cell}:nth-child(4) {
    flex: 1;
    height: 6%;
  }

  ${Cell}:nth-child(5) {
    width: 100%;
    height: 6%;
  }

  ${Cell}:nth-child(6) {
    width: 100%;
    height: 25%;
  }
`;

export default BhScreenDbStyled;
