import { css } from 'styled-components';

import { Cell } from '../styles';

const MagyarBase9Styled = css`
  display: flex;
  flex-direction: column;
  gap: 1px;

  ${Cell}:nth-child(1) {
    width: 100%;
    height: 17%;
  }

  ${Cell}:nth-child(2) {
    width: 100%;
    height: 10%;
  }

  ${Cell}:nth-child(3) {
    width: 100%;
    height: 10%;
  }

  ${Cell}:nth-child(4) {
    width: 100%;
    height: 10%;
  }

  ${Cell}:nth-child(5) {
    width: 100%;
    flex-grow: 1;
  }

  ${Cell}:nth-child(6) {
    width: 100%;
    flex-grow: 1;
  }

  ${Cell}:nth-child(7) {
    width: 100%;
    height: 10%;
  }

  ${Cell}:nth-child(8) {
    width: 100%;
    height: 10%;
  }

  ${Cell}:nth-child(9) {
    width: 100%;
    height: 17%;
  }
`;

export default MagyarBase9Styled;
