import { Loader } from '@omnipkg/ui-kit-web';

import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { VerticalCenteringWrapper } from '@src/theme/common';

import Stack from './components/Stack';
import useDeviceConfigs from './hooks/useDeviceConfigs';
import { MOCKED_CELLS } from './mocks/cells';
import { StackName } from './types';

import {
  ParcelLockerContainer,
  ParcelLockerHead,
  ParcelLockerWrapper,
  StacksContainer,
} from './styled';

export default function ParcelLocker(): JSX.Element {
  const { openedCell } = useDeviceInfoContext();

  const { isLoading, deviceConfig } = useDeviceConfigs();

  const shownData = deviceConfig ? (
    deviceConfig.stacks.map(({ cells, stack: stackId, name }) => {
      const { cell, stack } = openedCell || {};
      const isActiveStack = stack === stackId;

      const isSkipTemplate = cells.length <= 1;

      if (isSkipTemplate) return;

      return (
        <Stack
          key={name + stackId}
          cells={cells}
          template={name}
          activeCell={cell}
          isActiveStack={isActiveStack}
        />
      );
    })
  ) : (
    <Stack
      template={StackName.Ultra17}
      isActiveStack={false}
      cells={MOCKED_CELLS}
    />
  );

  return isLoading ? (
    <VerticalCenteringWrapper>
      <Loader />
    </VerticalCenteringWrapper>
  ) : (
    <ParcelLockerContainer>
      <ParcelLockerWrapper>
        <ParcelLockerHead />
        <StacksContainer>{shownData}</StacksContainer>
      </ParcelLockerWrapper>
    </ParcelLockerContainer>
  );
}
