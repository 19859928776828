import { useEffect } from 'react';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';

import Loading from './components/Loading/Loading';
import { useLabelCreation } from './hooks/useLabelCreation';
import { useLabelLink } from './hooks/useLabelLink';
import { usePlaceParcelHandler } from './hooks/usePlaceParcelHandler';
import PrintLabeImg from './images/print-label-min.jpeg';
import PrintSuccess from './images/print-success-min.jpeg';

import { Image, Title } from './styled';

export default function PrintLabel() {
  const { labelLink } = useOrderDataContext();
  const { setButtonState } = useFlowManagerContext();
  const { isPending: isPendingLabel, getLabelLink } = useLabelLink();
  const { isPending: isPendingPlace, placeParcelHandler } =
    usePlaceParcelHandler();
  const { isError, isPending, isPrintSuccess, printLabelHandler } =
    useLabelCreation();

  const buttonText = isError ? 'Try again' : 'Print';
  const { image, title } = isPrintSuccess
    ? {
        image: PrintSuccess,
        title: 'Label is printing, place it on the parcel to send it out',
      }
    : {
        image: PrintLabeImg,
        title: 'Please, print the label to send the parcel',
      };

  useEffect(() => {
    getLabelLink();
  }, [getLabelLink]);

  useEffect(() => {
    if (isPrintSuccess) {
      setButtonState({
        text: 'Open cell',
        isActive: true,
        isLoading: isPendingPlace,
        buttonAction: placeParcelHandler,
      });
    } else {
      setButtonState({
        text: buttonText,
        isActive: true,
        isLoading: isPending || isPendingLabel,
        buttonAction: printLabelHandler,
      });
    }
  }, [
    labelLink,
    isPending,
    buttonText,
    isPendingLabel,
    isPrintSuccess,
    isPendingPlace,
    printLabelHandler,
    setButtonState,
    placeParcelHandler,
  ]);

  return (
    <>
      {isPending ? (
        <Loading />
      ) : (
        <FlowManager title="Label printing">
          <Image src={image} alt="" width="300" />
          <Title>{title}</Title>
        </FlowManager>
      )}
    </>
  );
}
