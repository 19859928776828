import { useEffect } from 'react';

import FlowManager from '@src/components/FlowManager';
import Message from '@src/components/Message';
import Error from '@src/components/SVGIcon/icons/Error';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

export default function PaymentError(): JSX.Element {
  const { setButtonState } = useFlowManagerContext();

  useEffect(() => {
    setButtonState({
      text: 'Try again',
      isActive: true,
      link: { to: Path.Payment },
    });
  }, [setButtonState]);

  return (
    <FlowManager>
      <Message icon={<Error />} title="Payment has not been made">
        Error in data exchange. <br />
        Try to pay again.
      </Message>
    </FlowManager>
  );
}
