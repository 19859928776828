import { useNavigate } from 'react-router-dom';
import { CourierPoint, LocationPoint, Widget } from '@omnic/widget-locations';

import { useDeliveryAddressContext } from '@src/contexts/DeliveryAddressContext';
import useINN from '@src/hooks/useINN';
import { Path } from '@src/types/routes';

import { initialZoomMap } from './constants';

import '@omnic/widget-locations/dist/lib/style.css';

import { ContainerWidget } from './styled';

const baseUrl = process.env.API_ECOM;

export default function DeliveryAddress() {
  const navigate = useNavigate();

  const { recordDeliveryAddress } = useDeliveryAddressContext();

  const inn = useINN();

  function onPointClick(point: LocationPoint | CourierPoint) {
    recordDeliveryAddress(point as LocationPoint);
    navigate(Path.AllTariffs);
  }

  return (
    <ContainerWidget>
      <div id="@omnic/widget-locations">
        <Widget
          config={{
            startScreen: 'map',
            hideLogo: true,
            disableCourierDelivery: true,
            INN: inn,
            locale: 'en',
            map: {
              initialZoom: initialZoomMap,
              provider: 'google',
            },
            radius: 2,
            font: 'Roboto',
            onPointClick,
          }}
          options={{
            baseURL: `${baseUrl}/type-1/public/`,
          }}
        />
      </div>
    </ContainerWidget>
  );
}
