import { ChangeEvent, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, useAuthContext } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useInputFieldsContext } from '@src/contexts/InputFieldsContext';
import { InputName } from '@src/contexts/InputFieldsContext/types';
import { Path } from '@src/types/routes';

import { useRecordUserData } from './utils/recordUserData';

import { InputsWrapper } from './styled';

export default function SenderDetails() {
  const navigate = useNavigate();

  const { token } = useAuthContext();
  const { inputFields, recordInputFields } = useInputFieldsContext();

  const { setButtonState } = useFlowManagerContext();

  const recordUserData = useRecordUserData();

  const isActive = !!(
    inputFields.senderName.trim() && inputFields.senderSurname.trim()
  );

  function onChangeHandler(event: ChangeEvent<HTMLInputElement>): void {
    const { value, name } = event.target;
    recordInputFields({ ...inputFields, [name as InputName]: value });
  }

  const buttonAction = useCallback(() => {
    if (isActive) {
      recordUserData({
        firstName: inputFields.senderName.trim(),
        lastName: inputFields.senderSurname.trim(),
        phone: inputFields.senderFullNumber,
        token,
      });
      navigate(Path.ParcelRules);
    }
  }, [
    token,
    isActive,
    inputFields.senderName,
    inputFields.senderSurname,
    inputFields.senderFullNumber,
    navigate,
    recordUserData,
  ]);

  useEffect(() => {
    setButtonState({
      text: 'Next',
      isActive: isActive,
      link: { to: Path.ParcelRules },
      buttonAction,
    });
  }, [isActive, buttonAction, setButtonState]);

  return (
    <FlowManager title="Enter the sender's details">
      <InputsWrapper>
        <Input
          label="Name"
          name={InputName.SenderName}
          value={inputFields.senderName}
          onChange={onChangeHandler}
          required
        />
        <Input
          label="Second name"
          name={InputName.SenderSurname}
          value={inputFields.senderSurname}
          onChange={onChangeHandler}
          required
        />
        <Input
          label="Phone number"
          value={inputFields.senderFullNumber}
          disabled
          required
        />
      </InputsWrapper>
    </FlowManager>
  );
}
