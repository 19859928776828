import { css } from 'styled-components';

import { Cell } from '../styles';

const BhPost10Styled = css`
  display: flex;
  flex-direction: column;
  gap: 1px;

  ${Cell}:nth-child(1) {
    flex: 3;
  }

  ${Cell}:nth-child(2) {
    flex: 1;
  }

  ${Cell}:nth-child(3) {
    flex: 1;
  }

  ${Cell}:nth-child(4) {
    flex: 1;
  }

  ${Cell}:nth-child(5) {
    flex: 1;
  }

  ${Cell}:nth-child(6) {
    flex: 1;
  }

  ${Cell}:nth-child(7) {
    flex: 1;
  }

  ${Cell}:nth-child(8) {
    flex: 1;
  }

  ${Cell}:nth-child(9) {
    flex: 2;
  }

  ${Cell}:nth-child(10) {
    flex: 2;
  }
`;

export default BhPost10Styled;
