import { Cell, CellsContainer } from './styles';
import { StackProps } from './types';

import { SizeContainer, StackBody, StackWrapper } from '../../styled';

export default function Stack({
  cells,
  template,
  isActiveStack,
  activeCell,
}: StackProps): JSX.Element {
  return (
    <SizeContainer role="presentation" aria-label="Parcel Locker">
      <StackWrapper>
        <StackBody>
          <CellsContainer $template={template}>
            {cells.map(({ cell }) => {
              const isActiveCell = isActiveStack && activeCell === cell;
              const ariaLabel = isActiveCell
                ? 'Cell with your order'
                : 'Parcel Locker cell';

              return (
                <Cell
                  key={cell}
                  $isActiveCell={isActiveCell}
                  aria-label={ariaLabel}
                />
              );
            })}
          </CellsContainer>
        </StackBody>
      </StackWrapper>
    </SizeContainer>
  );
}
