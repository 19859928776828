import { Extra } from '@omnipkg/ui-kit-web';

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  UAH = 'UAH',
  SAR = 'SAR',
}

export interface CountryMeasurements {
  unitOfLength: string;
  unitOfWeight: string;
  currency: string;
}

export interface CountrySettings {
  country_code: string;
  currency: Currency;
  default_map_service: string;
  default_measurement_length: string;
  default_measurement_weight: string;
  languages: string[];
  vat_rates: number[];
}

export interface CellsStatusResponse {
  device_uid: string;
  cell_types: ExtendedCellsInfo[];
}

export interface ExtendedCellsInfo {
  type: string;
  has_empty: boolean;
  params: CellParams;
}

export interface CellParams {
  width: number;
  height: number;
  depth: number;
  label: string;
}

export interface CellsStatusResponse {
  type: string;
  has_empty: boolean;
  params: CellParams;
}

export interface CellParams {
  width: number;
  height: number;
  depth: number;
  label: string;
}

export interface DeviceInfoResponse {
  device: string;
  name: string;
  type: number;
  partner_type: number;
  location: Location;
  description: string;
  point_code: string;
  is_online: boolean;
  uid: string;
  has_empty: boolean;
  images: string[];
  weight_limit: string;
  max_cell_size: CellSize;
  location_description: string;
  support_phone: string;
  station: string;
  battery_info: BatteryInfo;
  network: string;
}

export interface Location {
  country: number;
  country_name: string;
  region: string;
  city: string;
  country_settings: CountrySettings;
  name: string;
  address: string;
  postal: string;
  latitude: string;
  longitude: string;
  working_hours: WorkingHours;
  uid: string;
}

export interface WorkingHours {
  '1': string[];
  '2': string[];
  '3': string[];
  '4': string[];
  '5': string[];
  '6': string[];
  '7': string[];
}

export interface CellSize {
  width: number;
  height: number;
  depth: number;
}

export interface BatteryInfo {}

export interface DeviceInfoContextValue {
  deviceInfo?: DeviceInfoResponse;
  deviceUid: string;
  selectCell?: ExtendedCellsInfo;
  openedCell?: Extra;
  countryMeasurements: CountryMeasurements;
  clearSelectCell: () => void;
  setDeviceInfo: React.Dispatch<
    React.SetStateAction<DeviceInfoResponse | undefined>
  >;
  setDeviceUid: React.Dispatch<React.SetStateAction<string>>;
  setSelectCell: React.Dispatch<
    React.SetStateAction<ExtendedCellsInfo | undefined>
  >;
  recordOpenedCell: (data: Extra) => void;
  recordDeviceInfo: (data: DeviceInfoResponse) => void;
}
