import { useCallback, useEffect, useState } from 'react';

import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useOnConfirm } from '@src/pages/Feedback/hooks/useOnConfirm';

import RadioButton from './components/RadioButton';
import { reasonOptions } from './constants';

import { Form, Textarea } from './styled';

export default function Reasons(): JSX.Element {
  const [selectedReason, setSelectedReason] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');
  const [showOtherInput, setShowOtherInput] = useState(false);

  const { isPending, onConfirm } = useOnConfirm();

  const isActive =
    (selectedReason !== '' && selectedReason !== 'Other') ||
    additionalComments !== '';

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedReason(value);

    setShowOtherInput(value === 'Other');
  };

  const handleCommentsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setAdditionalComments(event.target.value);
  };

  const buttonAction = useCallback(() => {
    onConfirm(selectedReason);

    setSelectedReason('');
    setAdditionalComments('');
    setShowOtherInput(false);
  }, [selectedReason, onConfirm]);

  const { setButtonState } = useFlowManagerContext();

  useEffect(() => {
    setButtonState({
      text: 'Submit',
      isActive,
      isLoading: isPending,
      buttonAction,
    });
  }, [isActive, isPending, buttonAction, setButtonState]);

  return (
    <Form>
      {reasonOptions.map(({ id, title }) => (
        <RadioButton
          key={id}
          value={title}
          checked={selectedReason === title}
          onChange={handleReasonChange}
        >
          {title}
        </RadioButton>
      ))}
      {showOtherInput && (
        <Textarea
          value={additionalComments}
          placeholder="Describe the reason"
          onChange={handleCommentsChange}
        />
      )}
    </Form>
  );
}
