import { useId } from 'react';
import { Input } from '@omnipkg/ui-kit-web';

import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useParcelContext } from '@src/contexts/ParcelContext';

import { InputWrapper, Label } from './styled';

export default function InputOfInsurance() {
  const inputID = useId();

  const {
    countryMeasurements: { currency },
  } = useDeviceInfoContext();
  const { parcelInfo, setParcelInfo } = useParcelContext();

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^0-9.,]/g, '');

    if (newValue.split(/\.|,/).length <= 2) {
      const parts = newValue.split(/\.|,/);

      if (parts.length === 2 && parts[1].length > 2) {
        return;
      }

      const formattedValue =
        parts.length === 2 && parts[0] === '' ? `0${parts[1]}.` : newValue;

      setParcelInfo('insurance', formattedValue.replace(/,/, '.'));
    }
  };

  return (
    <InputWrapper>
      <Label htmlFor={inputID}>{currency}</Label>
      <Input
        id={inputID}
        type="text"
        value={parcelInfo.insurance}
        onChange={handleWeightChange}
        inputMode="decimal"
        pattern="[0-9.,]"
        placeholder="- - - -"
      />
    </InputWrapper>
  );
}
