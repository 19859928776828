import { useEffect, useState } from 'react';
import { Loader } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { ExtendedCellsInfo } from '@src/contexts/DeviceInfoContext/types';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { VerticalCenteringWrapper } from '@src/theme/common';
import { Path } from '@src/types/routes';

import NoAvailableCells from './components/NoAvailableCells';
import Ultra from './components/Ultra';
import { convertedIdx } from './constants';
import useGetCellsStatusesRequest from './hooks/useGetCellsStatusesRequest';
import useGetDeviceInfoRequest from './hooks/useGetDeviceInfoRequest';

export default function Main() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAvailableCells, setIsAvailableCells] = useState(true);
  const [cells, setCells] = useState<ExtendedCellsInfo[]>([]);
  const [filteredCells, setFilteredCells] = useState<ExtendedCellsInfo[]>([]);

  const { deviceUid, selectCell, setDeviceInfo } = useDeviceInfoContext();
  const { setButtonState } = useFlowManagerContext();

  const getDeviceInfo = useGetDeviceInfoRequest();
  const getCellsStatuses = useGetCellsStatusesRequest();

  // TODO: remove this const after implementing the logic
  const isCellsTemplateAvailable = true;

  const { title, component, buttonText, nextPage } = isAvailableCells
    ? {
        title: 'Choose a cell on the size of your package',
        component: <Ultra cells={filteredCells} />,
        buttonText: 'Next',
        nextPage: Path.ParcelSize,
      }
    : {
        title: undefined,
        component: <NoAvailableCells />,
        buttonText: 'View the map',
        nextPage: Path.DeliveryAddress,
      };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      if (!deviceUid) {
        setIsAvailableCells(false);
        return;
      }

      getDeviceInfo({
        deviceUid,
      })
        .then((device) => {
          setDeviceInfo(device);
          setIsAvailableCells(device.has_empty);

          if (device.has_empty) {
            return getCellsStatuses({
              deviceUid,
            });
          }
        })
        .then((data) => {
          if (data) setCells(data.cell_types);
        })
        .catch(() => setIsAvailableCells(false))
        .finally(() => setIsLoading(false));
    })();
  }, [deviceUid, getCellsStatuses, getDeviceInfo, setDeviceInfo]);

  useEffect(() => {
    if (cells) {
      const processedCells: ExtendedCellsInfo[] = [];

      const sortedCells = [...cells].sort((cellA, cellB) => {
        const cellASquare = cellA.params.height * cellA.params.width;
        const cellBSquare = cellB.params.height * cellB.params.width;

        return cellASquare - cellBSquare;
      });

      if (isCellsTemplateAvailable) {
        sortedCells.forEach((cell, idx) => {
          processedCells[convertedIdx[idx as keyof typeof convertedIdx]] = cell;
        });

        setFilteredCells(processedCells);

        return;
      }

      setFilteredCells(sortedCells);
    }
  }, [cells, isCellsTemplateAvailable]);

  useEffect(() => {
    setButtonState({
      text: buttonText,
      link: { to: nextPage },
      isActive: !!selectCell,
    });
  }, [buttonText, nextPage, selectCell, setButtonState]);

  if (isLoading) {
    return (
      <VerticalCenteringWrapper>
        <Loader />
      </VerticalCenteringWrapper>
    );
  }

  return (
    <FlowManager title={title}>
      <VerticalCenteringWrapper>{component}</VerticalCenteringWrapper>
    </FlowManager>
  );
}
