import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

import { Stack } from '../types';

interface DeviceConfigsArgs {
  deviceUid: string;
}

interface DeviceConfigsRequest {
  device_uid: string;
}

export interface DeviseConfigResponse {
  device: string;
  stacks: Stack[];
}

const baseURL = process.env.API;

export function useGetDeviceConfigs() {
  const { request } = useApiContext();

  return useCallback(
    async ({ deviceUid }: DeviceConfigsArgs): Promise<DeviseConfigResponse> => {
      const { data } = await request<
        DeviseConfigResponse,
        DeviceConfigsRequest
      >({
        baseURL,
        url: `/device/${deviceUid}/configuration/view/`,
        method: 'GET',
      });

      return data;
    },
    [request],
  );
}
