import { useEffect } from 'react';
import { SVGIcon } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import Message from '@src/components/Message/Message';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

const icon = <SVGIcon name="like-face" fillVariant="primary" size={100} />;

export default function Like() {
  const { setButtonState } = useFlowManagerContext();

  useEffect(() => {
    setButtonState({
      text: 'Back home',
      isActive: true,
      isLoading: false,
      link: { to: Path.Main },
    });
  }, [setButtonState]);

  return (
    <FlowManager>
      <Message icon={icon} title="Thank you!">
        We hope to see you back soon!
      </Message>
    </FlowManager>
  );
}
