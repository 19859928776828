import { useEffect, useState } from 'react';
import { Loader } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useDeliveryAddressContext } from '@src/contexts/DeliveryAddressContext';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useParcelContext } from '@src/contexts/ParcelContext';
import { useTariffsContext } from '@src/contexts/TariffsContext';
import { Tariff } from '@src/contexts/TariffsContext/types';
import useINN from '@src/hooks/useINN';
import { Title, VerticalCenteringWrapper } from '@src/theme/common';
import { Path } from '@src/types/routes';

import TariffItem from './components/TariffItem';
import useGetTariffCalculatorRequest from './hooks/useGetTariffCalculatorRequest';

import { TariffsList } from './styled';

export default function AllTariffs() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [tariffs, setTariffs] = useState<Tariff[]>();

  const { parcelInfo } = useParcelContext();
  const { deviceInfo } = useDeviceInfoContext();
  const { selectedTariff } = useTariffsContext();
  const { deliveryAddress } = useDeliveryAddressContext();
  const { setButtonState } = useFlowManagerContext();

  const inn = useINN();

  const getTariffs = useGetTariffCalculatorRequest();

  useEffect(() => {
    if (!deviceInfo || !deliveryAddress || !parcelInfo) return;

    setIsError(false);

    getTariffs({
      token: btoa(inn.toString()),
      from: {
        countryCode: deviceInfo.location.country_settings.country_code,
        region: deviceInfo.location.region,
        city: deviceInfo.location.city,
      },
      to: {
        pointTypes: deliveryAddress.type,
        countryCode: deliveryAddress.location.country.abbreviation,
        region: deliveryAddress.location.region.name,
        city: deliveryAddress.location.city.name,
      },
      places: {
        height: parcelInfo.height,
        length: parcelInfo.length,
        width: parcelInfo.width,
        weight: Number(parcelInfo.weight),
        insurance: Number(parcelInfo.insurance),
      },
    })
      .then((data) => {
        setTariffs(data);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [deliveryAddress, deviceInfo, inn, parcelInfo, getTariffs]);

  useEffect(() => {
    if (isError) {
      setButtonState({
        text: 'Back',
        link: { to: Path.DeliveryAddress },
        isActive: true,
      });
    } else {
      setButtonState({
        text: 'Next',
        link: { to: Path.DeliveryDetails },
        isActive: !!selectedTariff,
      });
    }
  }, [isError, selectedTariff, setButtonState]);

  return (
    <FlowManager title="Select delivery plan">
      {isLoading ? (
        <VerticalCenteringWrapper>
          <Loader />
        </VerticalCenteringWrapper>
      ) : (
        <>
          {tariffs?.length ? (
            <TariffsList>
              {[...tariffs]
                .sort((a, b) => a.delivery_cost - b.delivery_cost)
                .map((tariff) => {
                  return <TariffItem key={tariff.code} tariff={tariff} />;
                })}
            </TariffsList>
          ) : (
            <VerticalCenteringWrapper>
              <Title>Tariff not found</Title>
            </VerticalCenteringWrapper>
          )}
        </>
      )}
    </FlowManager>
  );
}
