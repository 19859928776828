import { ChangeEvent, useEffect } from 'react';
import { Input } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useParcelContext } from '@src/contexts/ParcelContext';
import { Section, Title } from '@src/theme/common';
import { Path } from '@src/types/routes';

import InputOfInsurance from './components/InputOfInsurance';
import { ContentsList, InsuranceList } from './constants';

import { ContentItem, List } from './styled';

export default function ParcelDescription() {
  const { setButtonState } = useFlowManagerContext();
  const { parcelInfo, setParcelInfo } = useParcelContext();

  const isActive = !!parcelInfo.description && Number(parcelInfo.insurance) > 0;
  const title = (
    <>
      Specify the <strong>contents</strong> of the parcel
    </>
  );

  function onChangeDescription(e: ChangeEvent<HTMLInputElement>) {
    setParcelInfo('description', e.target.value);
  }

  useEffect(() => {
    setButtonState({
      text: 'Next',
      isActive,
      link: { to: Path.DeliveryAddress },
    });
  }, [isActive, setButtonState]);

  return (
    <FlowManager title={title}>
      <Input
        name="description"
        type="text"
        placeholder="Content of the parcel"
        value={parcelInfo?.description}
        onChange={onChangeDescription}
      />

      <List>
        {ContentsList.map((item) => (
          <ContentItem
            key={item}
            onClick={() => setParcelInfo('description', item)}
          >
            {item}
          </ContentItem>
        ))}
      </List>

      <Section>
        <Title>
          Specify the sum <strong>insured</strong>
        </Title>
        <InputOfInsurance />
      </Section>

      <List>
        {InsuranceList.map((item) => (
          <ContentItem
            key={item}
            onClick={() => setParcelInfo('insurance', item)}
          >
            {item}
          </ContentItem>
        ))}
      </List>
    </FlowManager>
  );
}
