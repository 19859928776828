import { Button } from '@omnipkg/ui-kit-web';

import { useOrderDataContext } from '@src/contexts/OrderDataContext';

import { Form } from './styled';

export default function LiqPayForm() {
  const { signature } = useOrderDataContext();

  return (
    <Form
      method="POST"
      action="https://www.liqpay.ua/api/3/checkout"
      acceptCharset="utf-8"
    >
      <input type="hidden" name="data" value={signature?.data} />
      <input type="hidden" name="signature" value={signature?.signature} />
      <Button label="Pay" variant="contained" />
    </Form>
  );
}
