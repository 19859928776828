import { useEffect } from 'react';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

import { Text } from './styled';

export default function PublicOffer(): JSX.Element {
  const { setButtonState } = useFlowManagerContext();

  useEffect(() => {
    setButtonState({
      text: 'Back',
      isActive: true,
      link: { to: Path.Authorization },
    });
  }, [setButtonState]);

  return (
    <FlowManager title="Public offer">
      <Text></Text>
    </FlowManager>
  );
}
