import styled from 'styled-components';

import { H2, Txt1 } from '@src/theme/common';

export const Title = styled.h1`
  ${H2}

  margin-top: ${({ theme }) => theme.spacing()};
`;

export const SubTitle = styled.h2`
  ${Txt1}
  width: 100%;
  text-align: center;
`;
