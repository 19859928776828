import { RadioButtonProps } from './types';

import { RadioCustom, RadioInput, StyledLabel } from './styled';

const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  checked,
  onChange,
  children,
}) => (
  <StyledLabel>
    <RadioInput
      type="radio"
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <RadioCustom />
    {children}
  </StyledLabel>
);

export default RadioButton;
