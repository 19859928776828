import styled, { css } from 'styled-components';

import { Breakpoints } from '@src/constants/breakpoints';

export const H1 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(32)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const H1Bold = css`
  ${H1}
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const H2 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(24)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const H2Bold = css`
  ${H2}
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const H3 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(20)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const H4 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(18)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const Txt1 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(16)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const Txt1Bold = css`
  ${Txt1}
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const Txt1Light = css`
  ${Txt1}
  font-weight: ${({ theme }): string => theme.typography.fontWeight[300]};
`;

export const Txt2 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(14)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  line-height: 1.4;
`;

export const Txt2Bold = css`
  ${Txt2}
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const Txt3 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(12)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const Txt3Bold = css`
  ${Txt3}
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const Container = css`
  padding: ${({ theme }) => `${theme.spacing(2.5)} ${theme.spacing(2)}`};

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: ${({ theme }) => theme.spacing(2.5)};
  }
`;

export const Title = styled.h1`
  ${H4}
  width: 100%;
  text-align: center;
`;

export const SubTitle = styled.h2`
  ${Txt2}
  width: 100%;
  text-align: center;

  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const TopContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CenterContainer = styled.div`
  flex-grow: 1;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const BottomContainer = styled.div``;

export const ImageStyled = styled.img`
  max-width: 100%;
  height: auto;

  display: block;
`;

export const StyledWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};

  border-radius: 8px;
  border: 1px solid #e5e5e5;

  padding: ${({ theme }) => theme.spacing(2)};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing(2)};

  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const VerticalCenteringWrapper = styled.div`
  min-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  color: ${({ theme }) => theme.palette.text.secondary};

  background-color: ${({ theme }) => theme.palette.primary[40]};
  border-radius: 6px;

  padding: ${({ theme }) => theme.spacing(2)};
`;

export const TotalLabel = styled.div`
  font-size: 14px;
`;

export const TotalValue = styled.div`
  font-size: 32px;
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(2.5)};
`;
