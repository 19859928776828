import styled from 'styled-components';

import { Txt1 } from '@src/theme/common';

export const InputWrapper = styled.div`
  width: 100%;

  position: relative;

  input {
    padding-left: ${({ theme }) => theme.spacing(2.5)};

    &::placeholder {
      ${Txt1}
      color: ${({ theme }) => theme.palette.grey[30]};
    }
  }
`;

export const Label = styled.label`
  ${Txt1}

  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.spacing(2)};

  transform: translateY(-50%);

  z-index: 1;
`;
