import styled from 'styled-components';

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const RadioCustom = styled.div`
  position: relative;

  width: 24px;
  height: 24px;

  border: 2px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: 50%;

  margin-right: ${({ theme }) => theme.spacing()};

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 12px;
    height: 12px;

    background-color: ${({ theme }) => theme.palette.primary[40]};
    border-radius: 50%;

    opacity: 0;
  }
`;

export const RadioInput = styled.input`
  display: none;

  &:checked ~ ${RadioCustom} {
    border-color: ${({ theme }) => theme.palette.primary[40]};

    &::before {
      opacity: 1;
    }
  }
`;
