import { useEffect, useState } from 'react';

import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useParcelContext } from '@src/contexts/ParcelContext';

import { ChangeType, InputOfDimensionProps, SelectedCellParams } from './types';

import { Button, FieldWrapper, InputWrapper, Label } from './styled';

export default function InputOfDimension({ type }: InputOfDimensionProps) {
  const [value, setValue] = useState(1);

  const {
    selectCell,
    countryMeasurements: { unitOfLength },
  } = useDeviceInfoContext();
  const { parcelInfo, setParcelInfo } = useParcelContext();

  const cellValue = selectCell?.params?.[SelectedCellParams[type]] || 0;
  const maxCellValue = Math.trunc(cellValue / 10);
  const minValue = 1;
  const isMinValue = minValue === value;
  const isMaxValue = maxCellValue <= value;

  const createChangeHandler = (changeType: ChangeType) => {
    return () => {
      const step = 1;

      if (
        (changeType === 'plus' && isMaxValue) ||
        (changeType === 'minus' && isMinValue)
      ) {
        return;
      }

      setValue((prevValue) =>
        changeType === 'plus' ? prevValue + step : prevValue - step,
      );
    };
  };

  useEffect(() => {
    const parcelLength = parcelInfo?.[type];

    if (parcelLength) setValue(Math.trunc(parcelLength / 10));
  }, [parcelInfo, type]);

  useEffect(() => {
    if (value > minValue) {
      const valueMM = value * 10;

      setParcelInfo(type, valueMM);
    }
  }, [type, value, setParcelInfo]);

  return (
    <FieldWrapper>
      <Label htmlFor={type}>{type}</Label>
      <InputWrapper>
        {!isMinValue && (
          <Button
            data-action="minus"
            type="button"
            onClick={createChangeHandler('minus')}
          >
            -
          </Button>
        )}
        {`${value} ${unitOfLength}`}
        {!isMaxValue && (
          <Button
            data-action="plus"
            type="button"
            onClick={createChangeHandler('plus')}
          >
            +
          </Button>
        )}
      </InputWrapper>
    </FieldWrapper>
  );
}
