import { useCallback } from 'react';
import { Extra, useApiContext } from '@omnipkg/ui-kit-web';

interface PlaceParcelRequest {
  device_uid: string;
  order_uid: string;
}

interface PlaceParcelArgs {
  deviceUid: string;
  orderUid: string;
}

export function usePlaceParcel() {
  const { request } = useApiContext();

  return useCallback(
    async ({
      deviceUid,
      orderUid,
    }: PlaceParcelArgs): Promise<Extra | undefined> => {
      const { extra } = await request<never, PlaceParcelRequest>({
        url: '/order/placing/web/',
        method: 'POST',
        data: {
          device_uid: deviceUid,
          order_uid: orderUid,
        },
      });

      return extra;
    },
    [request],
  );
}
