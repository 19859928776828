import { Cell } from '../types';

export const MOCKED_CELLS: Cell[] = [
  {
    cell: 0,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 1,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 2,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 3,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 4,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 5,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 6,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 7,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 8,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 9,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 10,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 11,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 12,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 13,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 14,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 15,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 16,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
  {
    cell: 17,
    depth: 0,
    height: 0,
    row: 0,
    screen: false,
    width: 0,
  },
];
