import { useDeliveryAddressContext } from '@src/contexts/DeliveryAddressContext';
import { useParcelContext } from '@src/contexts/ParcelContext';

import {
  Bold,
  CellDetailsWrapper,
  DeliveryDetailsStyled,
  Text,
  Title,
} from './styled';

export default function Details() {
  const { deliveryAddress } = useDeliveryAddressContext();
  const { parcelInfo } = useParcelContext();

  const parcelWeight = parcelInfo.weight;
  const parcelHeight = Math.trunc(parcelInfo.height / 10);
  const parcelWidth = Math.trunc(parcelInfo.width / 10);
  const parcelLength = Math.trunc(parcelInfo.length / 10);
  const fullAddress = deliveryAddress?.location.full_address;
  const address = fullAddress ? fullAddress : 'Not found';

  return (
    <DeliveryDetailsStyled>
      <Title>Delivery Details</Title>

      <CellDetailsWrapper>
        <Bold>Cell size:</Bold>
        <Text>
          {`${parcelHeight}x${parcelWidth}x${parcelLength} cm`} |
          {`${parcelWeight} kg`}
        </Text>
      </CellDetailsWrapper>

      <CellDetailsWrapper>
        <Bold>Delivery address:</Bold>
        <Text>{address}</Text>
      </CellDetailsWrapper>
    </DeliveryDetailsStyled>
  );
}
