import { css } from 'styled-components';

import { Cell } from '../styles';

const X5Cells10Styled = css`
  display: flex;
  flex-direction: column;
  gap: 1px;

  ${Cell}:nth-child(1) {
    height: 11%;
  }

  ${Cell}:nth-child(2) {
    height: 11%;
  }

  ${Cell}:nth-child(3) {
    height: 11%;
  }

  ${Cell}:nth-child(4) {
    height: 7%;
  }

  ${Cell}:nth-child(5) {
    height: 7%;
  }

  ${Cell}:nth-child(6) {
    height: 7%;
  }

  ${Cell}:nth-child(7) {
    height: 11%;
  }

  ${Cell}:nth-child(8) {
    height: 11%;
  }

  ${Cell}:nth-child(9) {
    height: 11%;
  }

  ${Cell}:nth-child(10) {
    height: 13%;
  }
`;

export default X5Cells10Styled;
