import { useEffect } from 'react';
import { Loader } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import {
  StyledWrapper,
  TotalLabel,
  TotalValue,
  TotalWrapper,
  VerticalCenteringWrapper,
} from '@src/theme/common';

import { useGetPayHandler } from './hooks/useGetPayHandler';

import {
  InstructionsItem,
  InstructionsList,
  OrderNumber,
  Section,
  Title,
} from './styled';

export default function Payment(): JSX.Element {
  const { orderData } = useOrderDataContext();

  const { isPending, getPayHandler } = useGetPayHandler();

  useEffect(() => {
    getPayHandler();
  }, [getPayHandler]);

  return isPending ? (
    <VerticalCenteringWrapper>
      <Loader />
    </VerticalCenteringWrapper>
  ) : (
    <FlowManager title="Your delivery has been registered">
      <StyledWrapper>
        <Section>
          <OrderNumber>№{orderData?.order_number}</OrderNumber>
          <TotalWrapper>
            <TotalLabel>Total cost:</TotalLabel>
            <TotalValue>
              ${' '}
              {orderData?.cost?.services_payment
                ? orderData.cost.services_payment[0].sum
                : 0}
            </TotalValue>
          </TotalWrapper>
        </Section>
      </StyledWrapper>

      <StyledWrapper>
        <Section>
          <Title>Shipping instructions</Title>
          <InstructionsList>
            <InstructionsItem>
              After successful payment, the cell will open automatically
            </InstructionsItem>
            <InstructionsItem>
              Put the parcel into the opening cell
            </InstructionsItem>
            <InstructionsItem>
              Close the cell and follow the further instructions. Do not close
              the form until you finish submitting it
            </InstructionsItem>
          </InstructionsList>
        </Section>
      </StyledWrapper>
    </FlowManager>
  );
}
