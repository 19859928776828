import DeliveryTime from '@src/components/DeliveryTime';
import { useTariffsContext } from '@src/contexts/TariffsContext';
import {
  StyledWrapper,
  TotalLabel,
  TotalValue,
  TotalWrapper,
} from '@src/theme/common';

import {
  DeliveryTimeLabel,
  DeliveryTimeValue,
  DeliveryTimeWrapper,
  TariffDetailsStyled,
} from './styled';

export default function Total() {
  const { selectedTariff } = useTariffsContext();

  const minDays = selectedTariff?.days_min;
  const maxDays = selectedTariff?.days_max;

  return (
    <StyledWrapper>
      <TariffDetailsStyled>
        <TotalWrapper>
          <TotalLabel>Total:</TotalLabel>
          <TotalValue>
            ${' '}
            {selectedTariff?.delivery_cost ? selectedTariff?.delivery_cost : 0}
          </TotalValue>
        </TotalWrapper>

        <DeliveryTimeWrapper>
          <DeliveryTimeLabel>Estimated delivery time:</DeliveryTimeLabel>
          <DeliveryTimeValue>
            <DeliveryTime minDays={minDays} maxDays={maxDays} />
          </DeliveryTimeValue>
        </DeliveryTimeWrapper>
      </TariffDetailsStyled>
    </StyledWrapper>
  );
}
