import { Loader } from '@omnipkg/ui-kit-web';

import { VerticalCenteringWrapper } from '@src/theme/common';

import { LoaderWrapper, Text, Title } from './styled';

export default function Loading() {
  return (
    <VerticalCenteringWrapper>
      <LoaderWrapper>
        <Loader />
        <Title>Label is printing</Title>
        <Text>It may take a few seconds</Text>
      </LoaderWrapper>
    </VerticalCenteringWrapper>
  );
}
