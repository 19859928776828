import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@omnipkg/ui-kit-web';

import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import {
  BottomContainer,
  CenterContainer,
  Title,
  TopContainer,
} from '@src/theme/common';
import { Path } from '@src/types/routes';

import LiqPayForm from './components/LiqPayForm/LiqPayForm';
import { FlowManagerProps } from './types';

export default function FlowManager({ title, children }: FlowManagerProps) {
  const [isPayBtn, setIsPayBtn] = useState(false);

  const { buttonState } = useFlowManagerContext();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === Path.Payment) {
      setIsPayBtn(true);
    }
  }, [pathname]);

  return (
    <>
      {title && (
        <TopContainer>
          <Title>{title}</Title>
        </TopContainer>
      )}
      <CenterContainer>{children}</CenterContainer>
      <BottomContainer>
        {isPayBtn ? (
          <LiqPayForm />
        ) : (
          <Button
            label={buttonState.text}
            variant="contained"
            link={buttonState.link}
            isLoading={buttonState.isLoading}
            disabled={!buttonState.isActive}
            onClick={buttonState.buttonAction}
          />
        )}
      </BottomContainer>
    </>
  );
}
