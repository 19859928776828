import { css } from 'styled-components';

import { Cell } from '../styles';

const UltraCommonStyled = css`
  display: grid;
  column-gap: 2px;
  row-gap: 1px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 20% 12% repeat(3, 9%) repeat(4, 6.5%) auto;

  ${Cell} {
    width: 100%;
    height: 100%;
  }

  ${Cell}:first-child {
    grid-column: 1 / 3;
    grid-template: 1;
  }

  ${Cell}:last-child {
    grid-column: 1 / 3;
    grid-template: 10;
  }
`;

export default UltraCommonStyled;
