import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorHandlerContext } from '@omnipkg/ui-kit-web';

import { useDeliveryAddressContext } from '@src/contexts/DeliveryAddressContext';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useInputFieldsContext } from '@src/contexts/InputFieldsContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { useParcelContext } from '@src/contexts/ParcelContext';
import { useTariffsContext } from '@src/contexts/TariffsContext';
import { Path } from '@src/types/routes';

import { usePlaceParcel } from '../utils/placeParcel';

interface UsePlaceParcelHandlerReturn {
  isPending: boolean;
  placeParcelHandler: () => void;
}

export function usePlaceParcelHandler(): UsePlaceParcelHandlerReturn {
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const { deviceInfo, recordOpenedCell, clearSelectCell } =
    useDeviceInfoContext();
  const { processingUid } = useOrderDataContext();
  const { handleError } = useErrorHandlerContext();
  const { clearParcelInfo } = useParcelContext();
  const { clearInputFields } = useInputFieldsContext();
  const { clearDeliveryAddress } = useDeliveryAddressContext();
  const { clearSelectedTariff } = useTariffsContext();

  const placeParcel = usePlaceParcel();

  const clearData = useCallback(() => {
    clearParcelInfo();
    clearSelectCell();
    clearInputFields();
    clearSelectedTariff();
    clearDeliveryAddress();
  }, [
    clearParcelInfo,
    clearSelectCell,
    clearDeliveryAddress,
    clearSelectedTariff,
    clearInputFields,
  ]);

  const placeParcelHandler = useCallback(() => {
    if (!deviceInfo || !processingUid) return;

    setIsPending(true);
    placeParcel({
      deviceUid: deviceInfo.uid,
      orderUid: processingUid,
    })
      .then((extra) => {
        if (extra) {
          recordOpenedCell(extra);
        }
        clearData();
        navigate(Path.PlaceParcel);
      })
      .catch((error) => handleError(error.message))
      .finally(() => setIsPending(false));
  }, [
    deviceInfo,
    processingUid,
    placeParcel,
    navigate,
    recordOpenedCell,
    clearData,
    handleError,
  ]);

  return {
    isPending,
    placeParcelHandler,
  };
}
