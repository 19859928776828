import DeliveryTime from '@src/components/DeliveryTime';
import { useTariffsContext } from '@src/contexts/TariffsContext';
import { Tariff } from '@src/contexts/TariffsContext/types';

import useGegDeliveryIcon from './hooks/useGegDeliveryIcon';
import { DeliveryPlanProps } from './types';

import {
  ImageWrapper,
  TariffDeliveryTime,
  TariffDescriptionContainer,
  TariffInfo,
  TariffName,
  TariffPrice,
  TariffPriceContainer,
  TariffWrapper,
} from './styled';

export default function TariffItem({ tariff }: DeliveryPlanProps): JSX.Element {
  const { selectedTariff, setSelectedTariff } = useTariffsContext();

  const isSelected = selectedTariff?.code === tariff.code;
  const {
    name,
    delivery_cost: price,
    days_min: minDays,
    days_max: maxDays,
    delivery_type: deliveryType,
  } = tariff;

  // TODO: add  SVGIcon from '@omnipkg/ui-kit-web'
  const icon = useGegDeliveryIcon(deliveryType);

  const onTariffClick = (currentTariff: Tariff) => () => {
    setSelectedTariff(currentTariff);
  };

  return (
    <TariffWrapper onClick={onTariffClick(tariff)} $isSelected={isSelected}>
      <TariffDescriptionContainer>
        <ImageWrapper>{icon}</ImageWrapper>
        <TariffInfo>
          <TariffName>{name}</TariffName>
          <TariffDeliveryTime>
            Delivery time: <DeliveryTime minDays={minDays} maxDays={maxDays} />
          </TariffDeliveryTime>
        </TariffInfo>
      </TariffDescriptionContainer>

      <TariffPriceContainer>
        <TariffPrice>$ {price}</TariffPrice>
      </TariffPriceContainer>
    </TariffWrapper>
  );
}
