import { useEffect } from 'react';

import FlowManager from '@src/components/FlowManager';
import Drugs from '@src/components/SVGIcon/icons/Drugs';
import ExplosiveItems from '@src/components/SVGIcon/icons/ExplosiveItems';
import Money from '@src/components/SVGIcon/icons/Money';
import PerishableProducts from '@src/components/SVGIcon/icons/PerishableProducts';
import Poison from '@src/components/SVGIcon/icons/Poison';
import Weapons from '@src/components/SVGIcon/icons/Weapons';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

import { Prohibition, Prohibitions, ProhibitionText } from './styled';

export default function ParcelRules(): JSX.Element {
  const { setButtonState } = useFlowManagerContext();

  const prohibitions = [
    {
      icon: <Poison />,
      text: 'Poison',
    },
    {
      icon: <ExplosiveItems />,
      text: 'Explosive items',
    },
    {
      icon: <Weapons />,
      text: 'Weapons',
    },
    {
      icon: <PerishableProducts />,
      text: 'Perishable products',
    },
    {
      icon: <Money />,
      text: 'Money',
    },
    {
      icon: <Drugs />,
      text: 'Drugs',
    },
  ];

  useEffect(() => {
    setButtonState({
      text: 'Confirm',
      isActive: true,
      link: { to: Path.RecipientDetails },
    });
  }, [setButtonState]);

  return (
    <FlowManager title="Prohibited and dangerous goods">
      <Prohibitions>
        {prohibitions.map(({ icon, text }) => (
          <Prohibition key={text}>
            {icon}
            <ProhibitionText>{text}</ProhibitionText>
          </Prohibition>
        ))}
      </Prohibitions>
    </FlowManager>
  );
}
