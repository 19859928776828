import Marker from '@src/assets/images/marker.jpg';
import ParcelLocker from '@src/assets/images/parcel-locker.jpg';
import { ImageStyled, StyledWrapper } from '@src/theme/common';

import { ErrorIconWrapper, ImageWrapper, LockIcon, Title } from './styled';

export default function NoAvailableCells() {
  return (
    <div>
      <ImageWrapper>
        <ImageStyled src={ParcelLocker} alt="No available cells" />
        <ErrorIconWrapper>
          <LockIcon />
        </ErrorIconWrapper>
      </ImageWrapper>
      <Title>
        Unfortunately, there are no free cells in this parcel locker
      </Title>
      <StyledWrapper>
        <ImageStyled src={Marker} alt="Marker" width="30" />
        Select another parcel locker on the locations map
      </StyledWrapper>
    </div>
  );
}
