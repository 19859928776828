import { Reason } from './types';

export const reasonOptions: Reason[] = [
  {
    id: 0,
    title: 'Complicated/uncomfortable return registration',
  },
  {
    id: 1,
    title: 'There were problems with payment',
  },
  {
    id: 2,
    title: 'There was no cell of the right size',
  },
  {
    id: 3,
    title: 'Hard to find a parcel locker/incorrect description',
  },
  {
    id: 4,
    title: 'Other',
  },
];
