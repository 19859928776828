import {
  AuthContextProvider,
  ErrorHandlerContextProvider,
  NotificationsContextProvider,
  ThemeContextProvider,
  TranslationContextProvider,
} from '@omnipkg/ui-kit-web';

import { DeliveryAddressContextProvider } from '@src/contexts/DeliveryAddressContext';
import { DeviceInfoContextProvider } from '@src/contexts/DeviceInfoContext';
import { FlowManagerContextProvider } from '@src/contexts/FlowManagerContext';
import { InputFieldsContextProvider } from '@src/contexts/InputFieldsContext';
import { OrderDataContextProvider } from '@src/contexts/OrderDataContext';
import { ParcelContextProvider } from '@src/contexts/ParcelContext';
import { TariffsContextProvider } from '@src/contexts/TariffsContext';
import { GlobalStyle } from '@src/theme/global';

import { useRoutes } from './hooks/useRoutes';

import { RootContainer } from './styled';

const en = require('@src/translations/en.json');

export default function App(): JSX.Element {
  const routes = useRoutes();

  return (
    <ThemeContextProvider>
      <GlobalStyle />
      <TranslationContextProvider
        translations={{
          en,
        }}
        defaultLang="en"
      >
        <NotificationsContextProvider>
          <ErrorHandlerContextProvider>
            <InputFieldsContextProvider>
              <DeviceInfoContextProvider>
                <ParcelContextProvider>
                  <FlowManagerContextProvider>
                    <DeliveryAddressContextProvider>
                      <TariffsContextProvider>
                        <OrderDataContextProvider>
                          <AuthContextProvider>
                            <RootContainer>{routes}</RootContainer>
                          </AuthContextProvider>
                        </OrderDataContextProvider>
                      </TariffsContextProvider>
                    </DeliveryAddressContextProvider>
                  </FlowManagerContextProvider>
                </ParcelContextProvider>
              </DeviceInfoContextProvider>
            </InputFieldsContextProvider>
          </ErrorHandlerContextProvider>
        </NotificationsContextProvider>
      </TranslationContextProvider>
    </ThemeContextProvider>
  );
}
