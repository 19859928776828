import { css } from 'styled-components';

import { Cell } from '../styles';

const X5Cells18Styled = css`
  display: grid;
  gap: 1px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 11% repeat(3, 9%) repeat(4, 6.5%) 11% auto;

  ${Cell} {
    width: 100%;
    height: 100%;
  }

  ${Cell}:nth-last-child(1) {
    grid-column: 1 / 3;
    grid-template: 10;
  }

  ${Cell}:nth-last-child(2) {
    grid-column: 1 / 3;
    grid-template: 1;
  }
`;

export default X5Cells18Styled;
