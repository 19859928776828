import { useEffect } from 'react';

import FlowManager from '@src/components/FlowManager';
import Message from '@src/components/Message';
import Success from '@src/components/SVGIcon/icons/Success';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

export default function PaymentSuccess(): JSX.Element {
  const { setButtonState } = useFlowManagerContext();

  useEffect(() => {
    setButtonState({
      text: 'Next',
      isActive: true,
      link: { to: Path.PrintLabel },
    });
  }, [setButtonState]);

  return (
    <FlowManager>
      <Message icon={<Success />} title="The payment was successful"></Message>
    </FlowManager>
  );
}
