import styled, { css } from 'styled-components';

export const TerminalIcon = styled.svg`
  height: 70%;
`;

export const stackCellsContainerCommon = css`
  width: 100%;
  height: 100%;
`;

export const stackCellCommon = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    156.57deg,
    rgba(38, 38, 38, 0.9) 17.03%,
    #323232 88.44%
  );
`;

export const ParcelLockerContainer = styled.main`
  width: 80%;
  justify-content: center;
  display: flex;

  margin: 0 auto;
`;

export const ParcelLockerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  max-width: 95%;
  padding: ${({ theme: { spacing } }): string =>
    `${spacing(1.5)} ${spacing()} ${spacing(5)}`};
  background-color: ${({ theme }): string => theme.palette.grey[100]};

  // Shadow
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    box-shadow: 0px 0px 10px 7px #000;
  }
`;

export const StacksContainer = styled.div`
  justify-content: center;
  max-width: 100%;
  gap: 5px;
  display: flex;
  flex-wrap: nowrap;
`;

export const ParcelLockerHead = styled.div`
  width: 100%;
  height: 12px;
  margin-bottom: 2px;

  background: linear-gradient(
    90deg,
    #383838 10.5%,
    #939393 60.08%,
    #434343 94.96%
  );
`;

export const SizeContainer = styled.div`
  flex: 1;
  flex-shrink: 1;
  width: 170px;
  max-width: 170px;
`;

export const StackWrapper = styled.div`
  position: relative;
  padding-top: 240%;
  width: 100%;
`;

export const StackBody = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;
