export enum StackName {
  Ultra17 = 'ultra-17',
  Ultra17Printer = 'ultra-17 printer',
  Ultra18 = 'ultra-18',
  PostuaUltraQpro = 'postua-ultra-qpro',
  PostuaUltra18 = 'postua-ultra-18',
  BhPost10 = 'bh-post-10',
  LaundryPost10 = 'laundry-post-10',
  BhTerminalPure = 'bh-terminal-pure',
  BhFresh = 'bh-fresh',
  BhVending = 'bh-vending',
  LaundryTerminalPure = 'laundry-terminal-pure',
  Loundry2 = 'loundry2',
  BhLaundry = 'bh_laundry',
  BhScreenDb = 'bh-screen-db',
  MagyarTerminal5 = 'magyar-terminal-5',
  MagyarBase9 = 'magyar-base-9',
  X5Terminal9 = 'terminal-x5-9',
  X5Cells10 = 'x5-10',
  X5Cells18 = 'x5-18',
}

export interface Cell {
  cell: number;
  width: number;
  height: number;
  depth: number;
  screen: boolean;
  row: number;
}

export interface Stack {
  name: StackName;
  stack: number;
  width: number;
  height: number;
  depth: number;
  cells: Cell[];
}

export interface CellPropsCommon {
  $isActiveCell: boolean;
}
