import { useEffect } from 'react';
import { Checkbox } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { Path } from '@src/types/routes';

import Details from './components/Details';
import Total from './components/Total';

export default function DeliveryDetails() {
  const { setButtonState } = useFlowManagerContext();
  const { isRecipientPays, recordWhoPays } = useOrderDataContext();

  const isRecipientPaysHandler = () => {
    recordWhoPays(!isRecipientPays);
  };

  useEffect(() => {
    setButtonState({
      text: 'Next',
      link: { to: Path.Authorization },
      isActive: true,
    });
  }, [setButtonState]);

  return (
    <FlowManager title="Delivery details">
      <Total />
      <Details />
      <Checkbox checked={isRecipientPays} onChange={isRecipientPaysHandler}>
        Payment for services is charged to the recipient
      </Checkbox>
    </FlowManager>
  );
}
