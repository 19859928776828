import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorHandlerContext } from '@omnipkg/ui-kit-web';

import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { Path } from '@src/types/routes';

import { useGetOrderExtending } from '../utils/GetOrderExtending';

interface HookReturn {
  isPending: boolean;
  getPayHandler: () => void;
}

export function useGetPayHandler(): HookReturn {
  const [isPending, setIsPending] = useState(true);

  const navigate = useNavigate();

  const { deviceInfo } = useDeviceInfoContext();
  const { orderData, recordSignature } = useOrderDataContext();
  const { handleError } = useErrorHandlerContext();

  const getOrderExtending = useGetOrderExtending();

  const { processingUid } = useOrderDataContext();

  const getPayHandler = useCallback(() => {
    if (!deviceInfo || !orderData.order_uid) return;

    const resultUrl = window.location.href;

    const signature = getOrderExtending({
      processingUid: processingUid,
      resultUrl,
    })
      .then((data) => {
        recordSignature(data);
      })
      .catch((error) => {
        if (error.message === 'order_has_already_paid') {
          return navigate(Path.PaymentSuccess);
        }

        handleError(error);
      })
      .finally(() => setIsPending(false));

    if (!signature) return;
  }, [
    deviceInfo,
    orderData.order_uid,
    processingUid,
    getOrderExtending,
    recordSignature,
    handleError,
    navigate,
  ]);

  return {
    isPending,
    getPayHandler,
  };
}
