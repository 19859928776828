import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Checkbox,
  PhoneInput,
  PhoneInputData,
  PhoneInputRef,
  useErrorHandlerContext,
} from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import Message from '@src/components/Message';
import Error from '@src/components/SVGIcon/icons/Error';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useInputFieldsContext } from '@src/contexts/InputFieldsContext';
import { useGetConfirmationCode } from '@src/hooks/useGetConfirmationCode';
import { SubTitle } from '@src/theme/common';
import { Path } from '@src/types/routes';

import { PhoneNumberWrapper, Text } from './styled';

export default function Authorization() {
  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const phoneRef = useRef<PhoneInputRef>(null);
  const navigate = useNavigate();

  const { deviceUid } = useDeviceInfoContext();
  const { inputFields, recordInputFields } = useInputFieldsContext();
  const { handleError } = useErrorHandlerContext();
  const { setButtonState } = useFlowManagerContext();
  const getConfirmationCode = useGetConfirmationCode();

  const title = isError ? undefined : 'Authorization';
  const buttonText = isError ? 'Try again' : 'Send code';
  const isActive = isError || (isAgreed && !!inputFields.senderFullNumber);

  const isAgreeHandler = () => {
    setIsAgreed((prev) => !prev);
  };

  function onPhoneChange({ fullNumber, number }: PhoneInputData): void {
    recordInputFields({
      ...inputFields,
      senderFullNumber: fullNumber,
      senderNumber: number,
    });
  }

  const getConfirmationCodeHandler = useCallback(() => {
    const isNumberCorrect = phoneRef.current?.getPhoneInfo().isNumberCorrect;

    if (!isNumberCorrect) {
      return;
    }

    if (isError) {
      return setIsError(false);
    }

    setIsLoading(true);

    getConfirmationCode({
      phone: inputFields.senderFullNumber,
      deviceUid: deviceUid,
    })
      .then(() => {
        navigate(Path.ConfirmationCode);
      })
      .catch((error) => {
        setIsError(true);
        handleError(error.message);
      })
      .finally(() => setIsLoading(false));
  }, [
    isError,
    deviceUid,
    inputFields.senderFullNumber,
    navigate,
    handleError,
    getConfirmationCode,
  ]);

  useEffect(() => {
    setButtonState({
      text: buttonText,
      isLoading,
      isActive,
      buttonAction: getConfirmationCodeHandler,
    });
  }, [
    isActive,
    isLoading,
    buttonText,
    setButtonState,
    getConfirmationCodeHandler,
  ]);

  return (
    <FlowManager title={title}>
      {isError ? (
        <Message icon={<Error />} title="Invalid phone number">
          Failed to send the code to the number you have entered. Check the
          entered data and try again.
        </Message>
      ) : (
        <>
          <SubTitle>Enter your phone number to receive SMS code</SubTitle>
          <PhoneNumberWrapper>
            <PhoneInput
              ref={phoneRef}
              initialNumber={inputFields.recipientFullNumber}
              onChange={onPhoneChange}
              countryPickerMode="onlyNumber"
            />
          </PhoneNumberWrapper>
          <Checkbox checked={isAgreed} onChange={isAgreeHandler}>
            <Text>
              I agree to the rules of{' '}
              <Link to={Path.PublicOffer}>public offer</Link>
            </Text>
          </Checkbox>
        </>
      )}
    </FlowManager>
  );
}
