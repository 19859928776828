import { DeliveryTimeProps } from './types';

export default function DeliveryTime({ minDays, maxDays }: DeliveryTimeProps) {
  let deliveryTime = '';

  if (!minDays || !maxDays) {
    return <>Not found</>;
  }

  if (minDays === maxDays) {
    deliveryTime = `${minDays} day`;
  } else {
    deliveryTime = `${minDays} - ${maxDays} days`;
  }

  return deliveryTime;
}
