import { SVGIcon } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import Message from '@src/components/Message';

import Reasons from './components/Reasons';

const icon = <SVGIcon name="dislike-face" fillVariant="error" size={100} />;

export default function Dislike(): JSX.Element {
  return (
    <FlowManager>
      <Message icon={icon} title="We're sorry you're not satisfied!">
        Specify the reason to help us improve our service.
        <Reasons />
      </Message>
    </FlowManager>
  );
}
