import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  margin-top: ${({ theme }) => theme.spacing(3)};
`;

export const Textarea = styled.textarea`
  width: 100%;
  min-height: 80px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.grey[10]};
  background: transparent;

  padding: ${({ theme }) => `${theme.spacing(2.25)} ${theme.spacing(2)}`};
  margin-top: ${({ theme }) => theme.spacing(3)};

  resize: none;

  &:focus {
    border-color: ${({ theme }) => theme.palette.primary[40]};
    outline: none;
  }
`;
