import styled from 'styled-components';

import { H1Bold, H4, Txt2 } from '@src/theme/common';

export const OrderNumber = styled.h2`
  ${H1Bold}
  text-align: center;

  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const Section = styled.div`
  width: 100%;
`;

export const Title = styled.h2`
  ${H4};

  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const InstructionsList = styled.ol`
  ${Txt2}

  padding-left: ${({ theme }) => theme.spacing(2)};
`;

export const InstructionsItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing()};
  }
`;
