import { css } from 'styled-components';

import { Cell } from '../styles';

const LoundryStyled = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1px;

  ${Cell} {
    flex: 1;
    height: 50%;
    min-width: 49%;
  }
`;

export default LoundryStyled;
