import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

interface GetOrderExtendingArgs {
  processingUid: string;
  resultUrl: string;
}

interface GetOrderExtendingResponse {
  signature?: string;
  data?: string;
}

const baseURL = process.env.API;

export function useGetOrderExtending() {
  const { request } = useApiContext();

  return useCallback(
    async ({
      processingUid,
      resultUrl,
    }: GetOrderExtendingArgs): Promise<GetOrderExtendingResponse> => {
      const { data } = await request<GetOrderExtendingResponse>({
        baseURL,
        url: `/order/extending/payload/${processingUid}/?result_url=${resultUrl}`,
        headers: {
          Authorization: null,
        },
      });

      return data;
    },
    [request],
  );
}
