import { StackName } from '@src/components/ParcelLocker/types';

import * as style from '../templates';

export default function useTemplate(name: StackName) {
  const templates = {
    [StackName.Ultra17]: style.UltraCommon,
    [StackName.Ultra17Printer]: style.UltraCommon,
    [StackName.Ultra18]: style.UltraCommon,
    [StackName.PostuaUltraQpro]: style.UltraCommon,
    [StackName.PostuaUltra18]: style.UltraCommon,
    [StackName.BhPost10]: style.BhPost10,
    [StackName.LaundryPost10]: style.BhPost10,
    [StackName.BhTerminalPure]: style.BhTerminalPure,
    [StackName.LaundryTerminalPure]: style.BhTerminalPure,
    [StackName.BhFresh]: style.BhFresh,
    [StackName.BhVending]: style.BhVending,
    [StackName.Loundry2]: style.Loundry,
    [StackName.BhLaundry]: style.Loundry,
    [StackName.BhScreenDb]: style.BhScreenDb,
    [StackName.MagyarTerminal5]: style.MagyarTerminal5,
    [StackName.MagyarBase9]: style.MagyarBase9,
    [StackName.X5Terminal9]: style.X5Terminal9,
    [StackName.X5Cells10]: style.X5Cells10,
    [StackName.X5Cells18]: style.X5Cells18,
  };

  return templates[name];
}
