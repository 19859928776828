import { css } from 'styled-components';

import { Cell } from '../styles';

const BhVendingStyled = css`
  display: flex;
  flex-direction: column;
  gap: 1px;

  ${Cell}:nth-child(1) {
    height: 30%;
  }

  ${Cell}:nth-child(2) {
    height: 35%;
  }

  ${Cell}:nth-child(3) {
    height: 35%;
  }
`;

export default BhVendingStyled;
