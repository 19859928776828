import { useEffect } from 'react';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useParcelContext } from '@src/contexts/ParcelContext';
import { Section, Title } from '@src/theme/common';
import { Path } from '@src/types/routes';

import InputOfDimension from './components/InputOfDimension';
import InputOfWeight from './components/InputOfWeight';

export default function ParcelSize() {
  const { setButtonState } = useFlowManagerContext();
  const { parcelInfo } = useParcelContext();

  const isActive =
    parcelInfo.height > 0 &&
    parcelInfo.width > 0 &&
    parcelInfo.length > 0 &&
    Number(parcelInfo.weight) > 0;

  const title = (
    <>
      Specify the <strong>weight</strong> of the parcel
    </>
  );

  useEffect(() => {
    setButtonState({
      text: 'Next',
      isActive: isActive,
      link: { to: Path.ParcelDescription },
    });
  }, [isActive, setButtonState]);

  return (
    <FlowManager title={title}>
      <InputOfWeight />

      <Section>
        <Title>
          Specify the <strong>dimensions</strong> of the parcel
        </Title>
        <InputOfDimension type="width" />
        <InputOfDimension type="height" />
        <InputOfDimension type="length" />
      </Section>
    </FlowManager>
  );
}
