import styled from 'styled-components';

export const DeliveryTimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const DeliveryTimeLabel = styled.div`
  font-size: 14px;
`;

export const DeliveryTimeValue = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
`;

export const TariffDetailsStyled = styled.div`
  flex: 1;
`;
