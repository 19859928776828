import { useEffect } from 'react';

import FlowManager from '@src/components/FlowManager';
import ParcelLocker from '@src/components/ParcelLocker/ParcelLocker';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

export default function PlaceParcel() {
  const { setButtonState } = useFlowManagerContext();

  useEffect(() => {
    setButtonState({
      text: 'Next',
      isActive: true,
      link: { to: Path.Feedback },
    });
  }, [setButtonState]);

  return (
    <FlowManager title="Place the parcel into the open cell">
      <ParcelLocker />
    </FlowManager>
  );
}
