export const InsuranceList = ['10', '20', '50', '100', '200'];

export const ContentsList = [
  'Gadgets',
  'Clothes',
  'Printed',
  'Cosmetics',
  'Sport gear',
  'Automotive Parts',
  'Shoes',
];
