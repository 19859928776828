import styled, { css, keyframes } from 'styled-components';

import useTemplate from './hooks/useTemplate';
import { CellPropsCommon, ContainerPropsCommon } from './types';

import { stackCellCommon, stackCellsContainerCommon } from '../../styled';

function applyCellStyle({ $isActiveCell }: CellPropsCommon) {
  const hightLight = keyframes`
      50% {
        background: linear-gradient(
          156.57deg,
          #38AB36 17.03%,
          #38AB36 88.44%
        );
      }
  `;

  return (
    $isActiveCell &&
    css`
      animation: ${hightLight} 2s infinite ease-in-out;
    `
  );
}

export const CellsContainer = styled.div<ContainerPropsCommon>(
  ({ $template }) => {
    const cssRules = useTemplate($template);

    return css`
      ${stackCellsContainerCommon};
      ${cssRules};
    `;
  },
);

export const Cell = styled.div<CellPropsCommon>`
  ${stackCellCommon};
  ${applyCellStyle};
`;
